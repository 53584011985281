import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as Yup from "yup";
import formStyles from "../formStyles";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import { SendUserBack } from "../helpers/authentication.js";
import MainLayout from "../layouts/mainLayout";
import CopyRight from "../components/copyright";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function LoginPage() {
  const { t } = useTranslation();

  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setIsShowPassword(!isShowPassword);
  };
  const handleMouseLeavePassword = (event) => {
    setIsShowPassword(false);
  };
  const loginSettings = {
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .required(t("validation.required")),
      password: Yup.string().required(t("validation.required")),
    }),
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      fetch("/account/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          setHttpResponse(data);
          if (!("error_code" in data)) {
            SendUserBack();
          }
        })
        .catch((error) => {
          setHttpResponse({ error_code: "500" });
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    },
  };

  return (
    <MainLayout>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("login")}
      </Typography>
      <Formik {...loginSettings}>
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.form}>
            {(httpResponse.error_code === "40100" && (
              <Alert variant="outlined" severity="error">
                {t("errors.wrong_email_or_password")}
              </Alert>
            )) ||
              (httpResponse.error_code === "40102" && (
                <Alert variant="outlined" severity="info">
                  {t("errors.no_pw_set")}
                </Alert>
              )) ||
              (httpResponse.error_code === "500" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong")}
                </Alert>
              )) ||
              (httpResponse.status === "success" && (
                <Alert variant="outlined" severity="success">
                  {t("success.login_success")}
                </Alert>
              )) ||
              (httpResponse && (
                //Default
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong_contact_admin")}
                </Alert>
              ))}
            <Field
              component={TextField}
              name="email"
              type="email"
              label={t("email")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              autoComplete="email"
              autoFocus
            />
            <br />
            <Field
              component={TextField}
              type={isShowPassword ? "text" : "password"}
              label={t("password")}
              name="password"
              variant="outlined"
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={handleMouseDownPassword}
                      onMouseLeave={handleMouseLeavePassword}
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              id="password"
              autoComplete="current-password"
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="remember"
              color="primary"
              Label={{ label: t(`remember_me`) }}
              id="remember"
            />
            {isSubmitting && <LinearProgress />}
            <br />
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              type="submit"
              fullWidth
              className={classes.submit}
            >
              {t("login")}
            </Button>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link component={ReactLink} to="/reset" variant="body2">
            {t("forgot_password")}
          </Link>
        </Grid>
        <Grid item>
          <Link component={ReactLink} to="/register" variant="body2">
            {t("not_signed_up")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
